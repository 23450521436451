import React from "react";
import { graphql, Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import { StaticImage } from "gatsby-plugin-image";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = {
    about: [
      {
        question: "What housing markets do you serve?",
        answer: (
          <>
            <p className="mb-0">
              We serve eight states, with areas as diverse as{" "}
              <Link to="/tempe-mortgage-lender/">Tempe</Link>, Arizona,{" "}
              <Link to="/las-vegas-mortgage-lender/">Las Vegas</Link>, Nevada,
              and{" "}
              <Link to="/san-bernardino-mortgage-lender/">San Bernardino</Link>,
              California.
            </p>
          </>
        ),
      },
      {
        question: "How long have you been in business?",
        answer: (
          <>
            <p className="mb-0">
              Right Start Mortgage was founded in 1989, and we’ve been providing
              home loans to loyal clients for the past 30 years. We’re a
              family-owned and operated company that believes in offering our
              customers a unique mortgage experience from beginning to end.{" "}
              <Link to="/meet-the-team/">Head here</Link> to meet our team.
            </p>
          </>
        ),
      },
    ],
    houseHunting: [
      {
        question: "Can I buy a home without a real estate agent?",
        answer: (
          <>
            <p>
              No law states you can’t purchase property without a real estate
              agent, and there are some benefits to doing so. For example, you
              can work directly with the seller of a “For Sale by Owner”
              property and perhaps get a reduced price on a property. However,
              if you go it on your own, you’ll need to do your own research and
              be your own representative when making an offer.
            </p>
            <p className="mb-0">
              The advantages of hiring a real estate agent are they’ll be able
              to give you access to a much wider choice of properties. Plus,
              you’ll have an experienced negotiator working for you, and they’ll
              help navigate you through all of the details that come into play
              during closing.
            </p>
          </>
        ),
      },
      {
        question: "What questions should I ask if I’m interested in a house?",
        answer: (
          <>
            <p>
              Buying a house is a big investment, and you’ll want to make sure
              you have all the information you need to make a decision. Here are
              three main questions to ask if you’ve found a home you like:
            </p>
            <p className="heading-six">
              How long has the home been on the market?
            </p>
            <p>
              If it’s been listed for a while, the seller might be eager to
              sell, which gives you a bargaining chip. If it’s just recently
              been listed, putting in a low offer won’t be as enticing to the
              seller.
            </p>
            <p className="heading-six">Why is the property up for sale?</p>
            <p>
              Not all realtors will share this information, but that can be
              valuable information for a buyer if they do.
            </p>
            <p className="heading-six">When was the home last renovated?</p>
            <p>
              Knowing when an owner last renovated will give you an idea of when
              you could expect to have to renovate the home yourself.
            </p>
            <p className="mb-0">
              Additionally, you'll want to get a feel for the neighborhood. Take
              a walk around the area to see what it's like where you might be
              living. Take note of any noises you hear (such as freeway traffic
              or trains) that might be bothersome. Check a local crime map. Talk
              to neighbors if you have the opportunity. And take a drive through
              the neighborhood at different times and days to see how the
              atmosphere changes.
            </p>
          </>
        ),
      },
      {
        question:
          "What do I need to consider if I’m looking at foreclosed homes?",
        answer: (
          <>
            <p>
              If you’re thinking of purchasing a foreclosed home, one of the
              most important steps you can take is to have it inspected so you
              can estimate repair costs and negotiate a concession with the
              seller.
            </p>
            <p className="mb-0">
              Also, you’ll want to ensure your earnest money deposit is
              refundable. With a foreclosed home, sometimes the purchase may not
              go through, and you’ll want to be able to recoup your earnest
              money if that happens.
            </p>
          </>
        ),
      },
    ],
    homeLoan: [
      {
        question: "What is an APR?",
        answer: (
          <>
            <p className="mb-0">
              An annual percentage rate (APR) reflects the cost of a mortgage as
              a yearly rate. It’s expressed as a percentage, but unlike an
              interest rate, it includes charges such as mortgage insurance,
              closing costs, points, and origination fees. This allows borrowers
              to compare loans on a level playing field.
            </p>
          </>
        ),
      },
      {
        question: "What does it mean to lock the interest rate?",
        answer: (
          <>
            <p className="mb-0">
              Interest rates can change throughout the loan process. Therefore,
              locking an interest rate can help prevent mortgage payments from
              rising unexpectedly if the rates go up sharply. Typically, a
              lender will allow a borrower to lock in a loan interest rate for a
              specified period (typically 30 to 60 days) and sometimes for a
              fee.
            </p>
          </>
        ),
      },
      {
        question: "What are points?",
        answer: (
          <>
            <p className="mb-0">
              Discount points are fees you pay to a lender to get a lower
              interest rate. It can help lower your monthly mortgage payment and
              possibly increase the loan amount you can afford to borrow.
              However, if you only plan to stay in the home for a short period,
              you might not be able to recoup the cost.
            </p>
          </>
        ),
      },
      {
        question: "How is my credit judged by lenders?",
        answer: (
          <>
            <p>
              When you apply for a loan, the lender will review your credit
              report to assess your ability to repay your loan. They’ll look at:
            </p>
            <ul className="styled-list-checkmark mb-6">
              <li>Your bill-paying history</li>
              <li>The number and type of accounts you have</li>
              <li>The age of your accounts</li>
              <li>Whether you’ve had late payments</li>
              <li>Whether you have any bills in collections</li>
              <li>What your outstanding debts are</li>
            </ul>
            <p>
              Many lenders will look at your FICO score, which scores your
              credit between 350 (high risk) and 850 (low risk). The better your
              credit, the better terms and interest rates you'll have access to.
            </p>
            <p className="heading-six">
              What can I do to improve a bad credit score?
            </p>
            <p>
              There are many steps you can take to improve your credit score.
              Here are some of the main ways you can make an impact:
            </p>
            <ul className="styled-list-checkmark">
              <li>
                Dispute credit report errors (get a free credit report at{" "}
                <a
                  href="https://www.annualcreditreport.com/index.action"
                  target="_blank"
                  rel="noreferrer"
                >
                  AnnualCreditReport.com
                </a>
                )
              </li>
              <li>Pay your bills on time</li>
              <li>Pay down your revolving credit</li>
              <li>
                Ask for higher credit limits to reduce your overall credit
                utilization (you’re aiming for 30% or less)
              </li>
              <li>Avoid applying for new credit</li>
              <li>Resolve collections</li>
            </ul>
          </>
        ),
      },
      {
        question: "What is PMI?",
        answer: (
          <>
            <p className="mb-0">
              Private mortgage insurance (often referred to as PMI) is insurance
              that protects lenders in the event you default on your loan. If
              you have a down payment of less than 20% on a conventional loan, a
              lender will require that you have private mortgage insurance. The
              cost will vary. You can either pay a 1-year premium at closing or
              have the PMI added to your monthly mortgage payment.
            </p>
          </>
        ),
      },
      {
        question: "What happens at closing?",
        answer: (
          <>
            <p>
              Closing is the most exciting part of the home buying journey
              because it’s when you will take ownership of the property. Before
              you’re handed the keys, though, there’s a lot that needs to
              happen:
            </p>
            <ul className="styled-list-checkmark">
              <li>
                The sales contract is agreed on by all parties and executed
              </li>
              <li>All past inspections are ordered</li>
              <li>You are given HOA information if you purchased a condo</li>
              <li>
                Arrangements are made for repairs outlined in the contract
              </li>
              <li>A holding deposit is sent to the title company</li>
              <li>You do a final walkthrough of the property</li>
              <li>
                Your loan application information is verified one last time
              </li>
              <li>
                You receive instructions on obtaining homeowner’s insurance
              </li>
              <li>
                Your lender finalizes your loan and sends documents to the title
                company
              </li>
              <li>
                The title company orders tax information and begins a title
                search
              </li>
              <li>Any pre-closing issues are resolved</li>
              <li>The title company prepares all required documents</li>
              <li>A closing date, time, and location are selected</li>
              <li>You sign all closing documents</li>
              <li>You get the keys to your new home!</li>
            </ul>
          </>
        ),
      },
    ],
    refinancing: [
      {
        question: "When should I refinance?",
        answer: (
          <>
            <p>
              The typical rule of thumb is that when interest rates drop by 1%
              or more, it’s a good time to consider refinancing your home loan.
              You can save money in the long-term, build equity in your home
              more quickly, and reduce your monthly payment.
            </p>
            <p>
              Falling interest rates are also a good time to convert from a
              fixed-rate loan to an adjustable-rate mortgage (ARM) if you want
              to take advantage of the dip.
            </p>
            <p className="mb-0">
              On the flip side, if you’ve found that you don’t like the
              fluctuations that come with an adjustable-rate mortgage, then
              refinancing to a fixed-rate loan can give you stability.
            </p>
          </>
        ),
      },
      {
        question: "Can I refinance if I have bad credit?",
        answer: (
          <>
            <p className="mb-0">
              A strong credit score is key to getting a great interest rate, but
              there are loan programs for those with less than perfect credit.
              Your lender can help identify the best loan opportunities for you.
            </p>
          </>
        ),
      },
      {
        question: "Are there out-of-pocket costs?",
        answer: (
          <>
            <p className="mb-0">
              Yes. You’ll need to account for closing costs to cover the fees
              for title insurance, appraisal, taxes, and transfer fees, among
              others. This typically runs between 3% and 6% of the loan’s
              principal. There are some loan programs with no closing costs, but
              it’s important to note that typically the fees will be recouped by
              giving you a higher interest rate.
            </p>
          </>
        ),
      },
    ],
    loanPayments: [
      {
        question:
          "Who can I contact if I have questions about my first payment?",
        answer: (
          <>
            <p className="mb-0">
              If you have questions about your first payment on your new loan or
              have not yet received a payment coupon, please call us at{" "}
              <a href="tel:800-520-5626">(800) 520-5626</a> or send us an email.
            </p>
          </>
        ),
      },
      {
        question:
          "What if I need help with an existing Right Start Mortgage loan?",
        answer: (
          <>
            <p className="mb-0">
              If you are looking for payoff information, payment or tax, or
              hazard account information, log in to the Servicing Portal to
              access your account. If you need other assistance with your
              account or would like to speak to someone directly, call{" "}
              <a href="tel:800-520-5626">(800) 520-5626</a>.
            </p>
          </>
        ),
      },
    ],
  };

  return (
    <Layout>
      <SearchEngineOptimization
        title="FAQs | Full-Service Mortgage Lender | Right Start"
        description="Get answers to frequently asked questions about buying or refinancing a home. And don't hesitate to contact us if you don't see your question listed."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <div className="container">
        <section className="relative mb-20 mt-4 overflow-hidden rounded-3xl py-16 px-4 md:mt-6 md:mb-32 md:py-24">
          <div className="absolute left-0 top-0 h-full w-full">
            <StaticImage
              src="../images/9.0 FAQs/Hero.jpg"
              loading="eager"
              className="h-full"
            />
          </div>

          <div className="relative mx-auto max-w-[560px] text-center">
            <h1 className="mb-4 text-white">FAQs</h1>
            <p className="mb-0 text-white">
              There are a lot of questions when it comes to buying or
              refinancing a home. Get answers here.
            </p>
          </div>
        </section>
      </div>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-16">
            <div className="space-y-20 md:col-span-8 md:col-start-1 md:space-y-32">
              <div id="section-1">
                <header className="mb-8 md:mb-10">
                  <h2>About Right Start Mortgage</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-200"
                >
                  {faqs.about.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-200 py-6 md:py-12"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="mb-0 font-heading font-bold text-typography-heading md:text-xl">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded ? "fa-minus" : "fa-plus"
                                      } text-2xl text-primary-500 md:text-4xl`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-2">
                <header className="mb-8 md:mb-10">
                  <h2>House Hunting</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-200"
                >
                  {faqs.houseHunting.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-200 py-6 md:py-12"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="mb-0 font-heading font-bold text-typography-heading md:text-xl">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded ? "fa-minus" : "fa-plus"
                                      } text-2xl text-primary-500 md:text-4xl`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-3">
                <header className="mb-8 md:mb-10">
                  <h2>Getting a Home Loan</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-200"
                >
                  {faqs.homeLoan.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-200 py-6 md:py-12"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="mb-0 font-heading font-bold text-typography-heading md:text-xl">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded ? "fa-minus" : "fa-plus"
                                      } text-2xl text-primary-500 md:text-4xl`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-4">
                <header className="mb-8 md:mb-10">
                  <h2>Refinancing Your Home</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-200"
                >
                  {faqs.refinancing.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-200 py-6 md:py-12"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="mb-0 font-heading font-bold text-typography-heading md:text-xl">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded ? "fa-minus" : "fa-plus"
                                      } text-2xl text-primary-500 md:text-4xl`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-5">
                <header className="mb-8 md:mb-10">
                  <h2>Your Loan Payments</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-200"
                >
                  {faqs.loanPayments.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-200 py-6 md:py-12"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="mb-0 font-heading font-bold text-typography-heading md:text-xl">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded ? "fa-minus" : "fa-plus"
                                      } text-2xl text-primary-500 md:text-4xl`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>
            </div>

            <ScrollNavigation
              className="hidden md:col-span-4 md:col-end-13 md:block"
              textAlignment="right"
            >
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="About Right Start Mortgage
"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="House Hunting
                  "
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Getting a Home Loan"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-4"}
                  title="Refinancing Your Home"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-5"}
                  title="Your Loan Payments
                  "
                  stripHash
                />
              </li>
            </ScrollNavigation>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
